
/* || Global Resets */
* {
    margin: 0;
    padding: 0;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif!important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


/* || .CLASS Selectors */

.chocolate {
    background-repeat:repeat;
    background-position: center;
    background-attachment: fixed;
    background-size: 150%;
    min-height: 90vh;
    max-width: 100vw;
    padding-top: 1rem;
    padding-bottom: 3rem;
}

.cdAbout {
    background-color: #ffa500;
    border: 2px solid #800080;
    border-radius: 7px;
    width: 95%;
    padding: 1rem;
    word-break: normal;
    text-align: start;
    margin-top: 1rem;
    margin-bottom: 0.7em;
    margin-right: auto;
    margin-left: auto;
}

.cdParagraph {
    background-color: #ffe2af;
    border: 2px solid #800080;
    border-radius: 7px;
    width: 100%;
    padding: 1rem;
    word-break: normal;
    text-align: start;
    margin-top: 0.3rem;
    margin-bottom: 0.7em;
}

.cdFavIcon {
    border: 3px solid #800080;
    box-shadow: 0px 0px 1px #adff2f inset, 0px 0px 8px #adff2f;
    margin-bottom: 1rem;
    border-radius: 15px;
}

.cdInstructions {
    color: #800080;
    text-shadow: -1px 0 #adff2f, 0 1px #adff2f, 1px 0 #ffa500, 0 -1px #ffa500;
    margin: 1rem;
    font-size: large;
}

.cdLikeIcon {
    border: 3px solid #800080;
    box-shadow: 0px 0px 1px #adff2f inset, 0px 0px 8px #adff2f;
}

.cdTagLine {
    color: #800080;
    text-shadow: -2px 0 #adff2f, 0 2px #adff2f, 2px 0 #ffa500, 0 -2px #ffa500;
    margin: 1rem;
    font-size: xx-large;
}

.commentBox {
    width: 100%;
    background-color: #ffe2af!important;
    margin-bottom: 0.5rem!important;
}

.dButt {
    background-color: #343a40!important;
}

.filling {
    margin: auto;
}

.flatLinkMuted {
    color: #6c757d!important;
    font-size: x-large;
}

    .flatLinkMuted:hover {
        color: #800080!important;
        text-decoration: none;
    }

.flatLinkOrange {
    color: #ffa500!important;
    text-decoration: none;
}

    .flatLinkOrange:hover {
        text-decoration: none;
        color: #adff2f!important;
        text-shadow: -1px 0 #0d0d0e, 0 1px #0d0d0e, 1px 0 #0d0d0e, 0 -1px #0d0d0e;
    }

.flatLinkPurple {
    color: #800080!important;
    text-decoration: none;
}

    .flatLinkPurple:hover {
        text-decoration: none;
        color: #adff2f!important;
        text-shadow: -1px 0 #0d0d0e, 0 1px #0d0d0e, 1px 0 #0d0d0e, 0 -1px #0d0d0e;
    }

.flatLinkRedirect {
    color: #800080!important;
    text-decoration: none;
    font-size: larger;
}

    .flatLinkRedirect:hover {
        text-decoration: none;
        color: #adff2f!important;
        text-shadow: -1px 0 #0d0d0e, 0 1px #0d0d0e, 1px 0 #0d0d0e, 0 -1px #0d0d0e;
    }

.flatLinkWotd {
    color: #800080!important;
    text-decoration: none;
    font-size: larger;
}

    .flatLinkWotd:hover {
        text-decoration: none;
        color: #ffa500!important;
        text-shadow: -1px 0 #0d0d0e, 0 1px #0d0d0e, 1px 0 #0d0d0e, 0 -1px #0d0d0e;
    }

.formButt {
    color: #800080!important;
    background-color: #ffa500!important;
    border: 3px solid #800080!important;
    border-radius: 7px!important;
    width: 100%!important;
    margin: auto!important;
}

.formButt:focus {
    color: #800080!important;
    background-color: #ffa500!important;
    border: 3px solid #800080!important;
    box-shadow: 0px 0px 1px #ffa500 inset, 0px 0px 8px #ffa500!important;
}

.formButt:hover {
    color: #800080!important;
    background-color: #ffa500!important;
    border: 3px solid #ffa500!important;
    box-shadow: 0px 0px 1px #ffa500 inset, 0px 0px 8px #ffa500!important;
}

.inlineList {
    list-style-type: none;
}

.isOffensive {
    font-size: x-large;
    text-shadow: -1px 0 #343A40, 0 1px #343A40, 1px 0 #343A40, 0 -1px #343A40;
}

.mgWordBreak {
    word-break: break-all;
}

.mgFormControl {
    background-color: #ffe1a39e;
}

    .mgFormControl:hover {
        background-color: #ffe1a39e;
    }

    .mgFormControl:focus {
        background-color: #ffe1a39e;
    }

.mgMargAuto {
    margin: auto;
}

.mgSearchControl:hover {
    background-color: #FFE1AF;
}

    .mgSearchControl:focus {
        background-color: #FFE1AF;
    }

.mgTxtRight {
    text-align: right;
}

.mgInlineBlock {
    display: inline-block;
}

.MuiContainer-root {
    margin-left: 0;
    margin-right: 0;
}

.qQuotes {
    color: #ffa500;
    text-shadow: -1px 0 #343A40, 0 1px #343A40, 1px 0 #343A40, 0 -1px #343A40;
}

.resHeading {
    text-align: center;
    width: 100%;
}

.rIAccordion {
    border: 2px solid #800080;
    margin-top: 0.16rem;
    margin-bottom: 0.16rem;
    background-color: #ffe2afa1!important;
}

    .rIAccordion:hover {
        border: 2px solid #ffa500;
        margin-top: 0.3rem;
        margin-bottom: 0.3rem;
    }

.rIWotdAccordion {
    border: 2px solid #800080!important;
    margin-top: 0.16rem;
    background-color: #ffe2af!important;
}

.rIInnerAccordion {
    border: 2px solid #ffa500;
    margin-top: 0.16rem;
    margin-bottom: 0.16rem;
    background-color: #ffffffab!important;
}

    .rIInnerAccordion:hover {
        border: 2px solid #800080;
        margin-top: 0.3rem;
        margin-bottom: 0.3rem;
    }

.rIWotdAccordion {
    border: 2px solid #800080!important;
    margin-top: 0.16rem;
    background-color: #ffe2af!important;
}

    .rIWotdAccordion:hover {
        border: 3px solid #ffa500!important;
        margin-top: 0.3rem;
    }

.rIAudio {
    box-shadow: 0px 0px 1px #800080 inset, 0px 0px 8px #800080;
    border: 3px solid #800080;
    border-radius: 30px;
    margin-top: 0.11rem;
    margin-bottom: 1.7rem;
    width: 75%;
}

    .rIAudio:hover {
        box-shadow: 0px 0px 1px #ffa500 inset, 0px 0px 8px #ffa500;
    }

.rIAudioSm {
    box-shadow: 0px 0px 1px #800080 inset, 0px 0px 8px #800080;
    border: 3px solid #800080;
    border-radius: 30px;
    margin-top: 0.7rem;
    margin-bottom: 1.7rem;
}

.rIOrange {
    color: #ffa500;
}

.rIPurple {
    color: #800080;
}

.rIGray {
    color: #343a40;
}

.rIGreen {
    color: #adff2f;
}

.rITipJar {
    border: 3px solid #800080!important;
    border-radius: 31px;
    box-shadow: 0px 0px 8px #ffa500 inset, 0px 0px 8px #ffa500;
}

.rITipJarChars {
    font-size: xx-small;
    word-break: break-all;
    margin-bottom: 0.5rem!important;
}

.rITipJars {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.sButt {
    color: #800080!important;
    background-color: #ffa500!important;
    border: 3px solid #800080!important;
    border-radius: 11px!important;
    margin-left: 0.5rem!important;
    margin-right: 0.5rem!important;
}

    .sButt:focus {
        color: #800080!important;
        background-color: #ffa500!important;
        border: 3px solid #800080!important;
        box-shadow: 0px 0px 1px #ffa500 inset, 0px 0px 8px #ffa500!important;
    }

    .sButt:hover {
        color: #800080!important;
        background-color: #ffa500!important;
        border: 3px solid #ffa500!important;
        box-shadow: 0px 0px 1px #ffa500 inset, 0px 0px 8px #ffa500!important;
    }

.sBox {
    border-color: #800080!important;
    box-shadow: 0px 0px 1px #800080 inset, 0px 0px 5px #800080!important;
    width: auto!important;
    background-color: #FFE1AF!important;
}

    .sBox:focus {
        border-color: #ffa500!important;
        box-shadow: 0px 0px 1px #ffa500 inset, 0px 0px 5px #ffa500!important;
        background-color: #FFE1AF!important;
    }

.sFooter {
    background-color: #343A40;
    padding: 1rem;
    margin-top: auto;
}

.sForm {
    display: flex!important;
    justify-content: space-between!important;
    align-items: center!important;
    margin: auto!important;
}

.socialIcon {
    border: 2px solid #800080;
    border-radius: 11px;
    box-shadow: 0px 0px 1px #adff2f inset, 0px 0px 8px #adff2f;
    margin: 0 0.45rem;
}

    .socialIcon:hover {
        box-shadow: 0px 0px 1px #ffa500 inset, 0px 0px 8px #ffa500;
    }

.syllables {
    text-shadow: -1px 0 #343A40, 0 1px #343A40, 1px 0 #343A40, 0 -1px #343A40;
}

.syllablesQuotes {
    color:#adff2f;
    text-shadow: -1px 0 #343A40, 0 1px #343A40, 1px 0 #343A40, 0 -1px #343A40;
}

.cdTooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted #800080;
}

    .cdTooltip .cdTooltipText {
        visibility: hidden;
        width: 8rem;
        font-size: small;
        background-color: #800080;
        color: #ffa500;
        text-align: center;
        border-radius: 7px;
        padding: 0.5rem 0.5rem;

        /* Position the tooltip */
        position: absolute;
        z-index: 1;
    }

    .cdTooltip:hover .cdTooltipText {
        visibility: visible;
    }

.topList {
    list-style-type: none;
    text-align: center;
}

.userDetails {
    font-size: large;
    word-break: break-all;
}


@media (max-width: 576px) {
    .chocolate {
        background-image: url('./images/paper_1_portrait.jpg');
    }

    .mgSmFont {
        font-size: smaller;
    }

    .filling {
        max-width: 100vw;
        padding: 0.5rem;
    }

    .rIAccordion p {
        font-size: small;
    }

    .rIAudio {
        width: 85%
    }

    .rIAudioSm {
        width: 95%;
    }
}

@media (min-width: 576px) {
    .chocolate {
        background-image: url('./images/paper_1_portrait.jpg');
    }

    .filling {
        max-width: 100vw;
        padding: 0.31rem;
    }

    .rIAudioSm {
        width: 75%;
    }
}

@media (max-width: 992px) {
    .cdNav {
        margin: auto;
        text-align: center;
    }
}

@media (min-width: 992px) {
    .chocolate {
        background-image: url('./images/paper_1_landscape.jpg');
        background-size: 100%;
    }

    .filling {
        max-width: 69vw;
    }
}